import React, { useEffect, useState } from 'react';
import css from './SectionProtection.css'
import { apiGetTransactions } from '../../util/api';
import { Avatar, NamedLink } from '../../components';

const SectionDetailUser = ({ user, reviewsAvg }) => {
    const [transactions, setTransactions] = useState(0);
    useEffect(() => {
        const fetchTransaction = async () => {
            const countTransactions = await apiGetTransactions({ user: user?.id?.uuid });
            setTransactions(countTransactions.transactions);
        };
        fetchTransaction()
    }, [user])

    return (
        <div className={css.containerSectionDetailUser}>
            <NamedLink name="ProfilePage" params={{ id: user.id.uuid }}>
                <div className={css.containerAvatar} >
                    <Avatar user={user} className={css.avatarDesktop} disableProfileLink />
                    <div>
                        <label>{user?.attributes?.profile?.displayName}</label>
                        <span>{transactions} ventas</span>
                    </div>
                </div>
            </NamedLink>
            <div className={css.containerText}>
                <span className={css.containerText_span}>{reviewsAvg ? reviewsAvg.toFixed(1) : 0}</span>
                <label className={css.containerText_label}>Reseñas</label>
            </div>
            <div className={css.containerText}>
                <span className={css.containerText_span}>{user?.attributes?.profile?.publicData?.followersCount ? user?.attributes?.profile?.publicData?.followersCount : 0}</span>
                <label className={css.containerText_label}>Seguidores</label>
            </div>
        </div>
    )
}

export default SectionDetailUser