import { CircularProgress, Grid } from '@material-ui/core';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import React, { Component } from 'react';
import {
  ReloveSearchFilters, ReloveSelectedFilters, SearchFiltersMobile, SearchResultsPanel, SortBy
} from '../../components';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { isAnyFilterActive } from '../../util/search';
import { propTypes } from '../../util/types';
import FilterComponent from './FilterComponent';
import css from './SearchPage.css';
import { validFilterParams } from './SearchPage.helpers';
import MediaQuery from 'react-responsive'


// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

/**
 * MainPanel contains search results and filters.
 * There are 3 presentational container-components that show filters:
 * SearchfiltersMobile, SearchFiltersPrimary, and SearchFiltersSecondary.
 * The last 2 are for desktop layout.
 */
class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { isSecondaryFiltersOpen: false, currentQueryParams: props.urlQueryParams };

    this.resetAll = this.resetAll.bind(this);

    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  // Clear other filters when new search query is made on the search bar
  componentDidUpdate(prevProps, prevState) {
    if (this.props.urlQueryParams.keywords !== prevProps.urlQueryParams.keywords) {
      this.setState({
        currentQueryParams: this.props.urlQueryParams
      });
    }
  }

  // Reset all filter query parameters, used by mobile filters
  resetAll(e) {
    const { urlQueryParams, history, filterConfig } = this.props;
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  // Used by the filters to get the inital value of themselves
  initialValues(queryParamNames) {
    // Query parameters that are visible in the URL
    const urlQueryParams = this.props.urlQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      return urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }

  // callback when submitting filters
  getHandleChangedValueFn(useHistoryPush) {
    const { urlQueryParams, history, sortConfig, filterConfig } = this.props;

    return updatedURLParams => {
      const updater = prevState => {
        const { keywords, sort, pub_category } = prevState.currentQueryParams;
        const prevCategories = pub_category?.split(',')
        const categories = updatedURLParams.pub_category?.split(',')
        if (updatedURLParams.pub_category && !prevCategories?.some(category => categories.includes(category))) {
          return { currentQueryParams: { ...updatedURLParams, keywords, sort } }
        }
        return { currentQueryParams: { ...urlQueryParams, ...updatedURLParams } };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, urlQueryParams } = this.props;
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      listings,
      loadListingsHandler,
      searchInProgress,
      appendInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      filterConfig,
      sortConfig,
      activeListingId,
      handleLoginRequest
    } = this.props;

    const primaryFilters = filterConfig.filter(f => f.group === 'primary');

    // Selected aka active filters
    const selectedFilters = validFilterParams(urlQueryParams, filterConfig);
    const selectedFiltersCount = Object.keys(selectedFilters).length;
    const topBrand = selectedFilters?.pub_brand? true : false;

    const keywords = urlQueryParams && urlQueryParams.keywords ? urlQueryParams.keywords : null;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems = searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : 0;
    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync && hasPaginationInfo;

    const sortBy = mode => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        urlQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
              rootClassName: css.sortBy,
              menuLabelRootClassName: css.sortByMenuLabel,
            }
          : {
              rootClassName: css.sortByDesktop,
              menuLabelRootClassName: css.sortByMenuLabel,
          };
      return sortConfig?.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={urlQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    let panelContent = null;

    if (searchInProgress && !appendInProgress) {
      panelContent = (
        <Grid container justifyContent={'center'} style={{marginTop: '10px'}}>
          <CircularProgress  className={css.loader} color={'inherit'}/>
        </Grid>
      );
    } else {
      if (searchListingsError) {
        panelContent = (
          <h2 className={css.error}>
            <FormattedMessage id="SearchPage.searchError" />
          </h2>
        );
      } else if (totalItems > 0) {
        panelContent = (
          <SearchResultsPanel
            className={css.searchListingsPanel}
            listings={listings}
            loadListingsHandler={loadListingsHandler}
            pagination={pagination}
            search={searchParamsForPagination}
            setActiveListing={onActivateListing}
            activeListingId={activeListingId}
            searchInProgress={searchInProgress}
            handleLoginRequest={handleLoginRequest}
          />
        );
      } else {
        panelContent = (
          <h2 className={css.noResults}>
            <FormattedMessage id="SearchPage.searchEmpty" />
          </h2>
        );
      }
    }

    const classes = classNames(rootClassName || css.searchResultContainer, className);

    return (
      <div className={classes}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} xl={2} className={css.side}>

          <MediaQuery maxWidth={700}>
            <SearchFiltersMobile
                className={css.searchFiltersMobile}
                urlQueryParams={urlQueryParams}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                showAsModalMaxWidth={showAsModalMaxWidth}
                onManageDisableScrolling={onManageDisableScrolling}
                onOpenModal={onOpenModal}
                onCloseModal={onCloseModal}
                resetAll={this.resetAll}
                selectedFiltersCount={selectedFiltersCount}
                sortByComponent={sortBy('mobile')}
                keywords={keywords}
              >
                {filterConfig.map(config => {
                  if (config.id === 'sizeShoes' && !selectedFilters.pub_category?.includes('Zapatos')) return null;
                  if (config.id === 'sizeAccessories' && !selectedFilters.pub_category?.includes('Accesorios')) return null;
                  if (config.id === 'size' && (selectedFilters.pub_category?.includes('Zapatos') || selectedFilters.pub_category?.includes('Accesorios'))) return null;
                  return (
                    <FilterComponent
                      key={`SearchFiltersMobile.${config.id}`}
                      idPrefix="SearchFiltersMobile"
                      filterConfig={config}
                      urlQueryParams={urlQueryParams}
                      initialValues={this.initialValues}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      isMobile
                    />
                  );
                })}
              </SearchFiltersMobile>
          </MediaQuery>
          <MediaQuery minWidth={700}>
            <ReloveSearchFilters
              className={css.searchFiltersPrimary}
            >
              {primaryFilters.map(config => {

                if (config.id === 'sizeShoes' && !selectedFilters.pub_category?.includes('Zapatos')) return null;
                if (config.id === 'sizeAccessories' && !selectedFilters.pub_category?.includes('Accesorios')) return null;
                if (config.id === 'size' && (selectedFilters.pub_category?.includes('Zapatos') || selectedFilters.pub_category?.includes('Accesorios'))) return null;

                return (
                  <FilterComponent
                    key={`SearchFiltersPrimary.${config.id}`}
                    idPrefix="SearchFiltersPrimary"
                    filterConfig={config}
                    urlQueryParams={urlQueryParams}
                    initialValues={this.initialValues}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    hasControls
                  />
                );
              })}
            </ReloveSearchFilters>
          </MediaQuery>
          </Grid>
          <Grid item xs={12} md={9} xl={10}className={css.main}>
            {keywords ? (
              <p className={css.searchDescriptionDesktop}>
                <FormattedMessage id="SearchPage.keywordDescription" values={{keywords}} />
              </p>
            ) : null}
            {
              topBrand ? null :
(              <div className={css.topSection}>
              <ReloveSelectedFilters
                selectedFilters={selectedFilters}
                onChange={this.getHandleChangedValueFn(true)}
              />
              <div className={css.sortContainer}>
                {sortBy('desktop')}
              </div>
            </div>
)            }
            
            <div
              className={css.listings}
            >
              {panelContent}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  activeListingId: null,
  searchParamsForPagination: {},
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,
  activeListingId: string,
  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default MainPanel;
