import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import {
  FieldPhoneNumberInput,
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  Modal,
  PrimaryButton,
  FieldBirthdayInput,
  FieldSelectSimple
} from '../../components';


import config from '../../config';

import css from './ProfileSettingsForm.css';

import cities from '../../util/municipios';

import defaultCover from '../../assets/default-profile-banner.jpg';
import { omitCodesPhone } from '../../util/codePhone';

const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
const identity = v => v;

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = {
      uploadDelay: false,
      cities: <option value="">Seleccione un municipio</option>,
    };
    this.submittedValues = {};
    this.handleChangeState = this.handleChangeState.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  handleChangeState(val) {
    let state = this.state;
    let citiesAr = cities[val.target.value] !== undefined ? cities[val.target.value].sort() : [];
    citiesAr = citiesAr.map(city => {
      return {
        nombre: city,
      };
    });
    citiesAr.unshift({ value: '', nombre: 'Seleccione un municipio' });
    state.cities = citiesAr
      .map(city => {
        if (city.value === undefined) {
          city.value = city.nombre;
        }
        return city;
      })
      .map((city, cityKey) => (
        <option value={city.value} key={cityKey}>
          {city.nombre}
        </option>
      ));
    this.setState(state);
  }

  render() {
    const codesPhone = omitCodesPhone(['MX']);

    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            formId,
            onCoverImageUpload,
            pristine,
            profileImage,
            coverImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            uploadCoverImageError,
            uploadCoverInProgress,
            form,
            values,
            initialValues,
            changePhoneNumber,
            alreadyTakenPhone,
          } = fieldRenderProps;
          // First name
          const firstNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameLabel',
          });
          const firstNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNamePlaceholder',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);

          // Last name
          const lastNameLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameLabel',
          });
          const lastNamePlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNamePlaceholder',
          });
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);

          // Age
          // const ageLabel = 'Edad'
          // const agePlaceholder = 'Edad';

          // birthday
          const birthdayLabel = intl.formatMessage({
            id: 'PayoutDetailsForm.birthdayLabel',
          });

          // Address
          const addressLabel = intl.formatMessage({
            id: 'ProfileSettingsForm.addressLabel',
          });
          const addressPlaceholder = intl.formatMessage({
            id: 'ProfileSettingsForm.addressPlaceholder',
          });
          const addressRequiredMessage = intl.formatMessage({
            id: 'ProfileSettingsForm.addressRequired',
          });
          const addressRequired = validators.required(addressRequiredMessage);

          const maxCharacters = validators.maxLength(
            'Longitud máxima de campo son 30 carácteres',
            30
          );

          const composeValidetors = validators.composeValidators(maxCharacters);
          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const user = ensureCurrentUser(currentUser);

          const phonePlaceholder = intl.formatMessage({
            id: 'ContactDetailsForm.phonePlaceholder',
          });

          const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });

          const phoneRequired = validators.requiredPhone(addressRequiredMessage);

          const functionChangeNumber = phone => {
            if (initialValues.phoneNumber !== phone) {
              changePhoneNumber(true);
            }
          };

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          /** COVER IMAGE **/
          const uploadingCoverOverlay =
            uploadCoverInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasCoverUploadError = !!uploadCoverImageError && !uploadCoverInProgress;
          const coverErrorClasess = classNames({ [css.coverUploadError]: hasCoverUploadError });

          // Ensure that file exists if imageFromFile is used
          const coverFileExists = !!coverImage.file;
          const coverFileUploadInProgress = uploadCoverInProgress && coverFileExists;
          const coverImageFromFile = coverFileExists ? (
            <ImageFromFile
              id={coverImage.id}
              className={coverErrorClasess}
              rootClassName={css.uploadingCoverImage}
              aspectRatioClassName={css.coverAspectRatio}
              imageClassName={css.coverImageRoot}
              file={coverImage.file}
            >
              {uploadingCoverOverlay}
            </ImageFromFile>
          ) : null;
          const coverImageFromLocation = coverImage.location ? (
            <div
              className={css.coverUploaded}
              style={{ backgroundImage: `url(${coverImage.location})` }}
            ></div>
          ) : null;

          const chooseCoverLabel =
            coverImage.location || coverFileUploadInProgress ? (
              <div className={css.coverContainer}>
                {coverImageFromFile}
                {coverImageFromLocation}
                <div className={css.changeCover}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.coverContainer}>
                <div
                  className={css.coverPlaceholder}
                  style={{ backgroundImage: `url(${defaultCover})` }}
                ></div>
                <div className={css.changeCover}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            );
          /* ------- */

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const zipErrorMessage = this.props.isUnavailable
            ? intl.formatMessage({
                id: 'ProfileSettingsForm.zipUnavailableShortError',
              })
            : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled = pristine || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

          const unavailableAddressAlertSection = (
            <Modal
              id="UniqueIdForThisUnavailableAddressAlert"
              isOpen={this.props.openZipErrorModal}
              onClose={this.props.onClosingAlert}
              usePortal
              contentClassName={css.modalContent}
              onManageDisableScrolling={this.props.onManageDisableScrolling}
            >
              <p>
                <FormattedMessage id="ProfileSettingsForm.zipCodeUnavailable" />
              </p>
              <PrimaryButton type="button" onClick={this.props.onClosingAlert}>
                Intentar de nuevo
              </PrimaryButton>
            </Modal>
          );

          const mexicoStates = config.states;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              {unavailableAddressAlertSection}
              <div className={css.imagesContainer}>
                <div className={css.coverImageWrapper}>
                  <Field
                    accept={ACCEPT_IMAGES}
                    id="coverImage"
                    name="coverImage"
                    label={chooseCoverLabel}
                    type="file"
                    form={null}
                    uploadImageError={uploadCoverImageError}
                    disabled={uploadCoverInProgress}
                  >
                    {fieldProps => {
                      const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                      const { name, type } = input;
                      const onChange = e => {
                        const file = e.target.files[0];
                        form.change(`coverImage`, file);
                        form.blur(`coverImage`);
                        if (file != null) {
                          const tempId = `${file.name}_${Date.now()}`;
                          onCoverImageUpload({ id: tempId, file });
                        }
                      };

                      let error = null;

                      if (isUploadImageOverLimitError(uploadImageError)) {
                        error = (
                          <div className={css.coverError}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                          </div>
                        );
                      } else if (uploadImageError) {
                        error = (
                          <div className={css.coverError}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                          </div>
                        );
                      }

                      return (
                        <div className={css.uploadCoverWrapper}>
                          {error}
                          <label className={css.label} htmlFor={id}>
                            {label}
                          </label>
                          <input
                            accept={accept}
                            id={id}
                            name={name}
                            className={css.uploadCoverInput}
                            disabled={disabled}
                            onChange={onChange}
                            type={type}
                          />
                        </div>
                      );
                    }}
                  </Field>
                </div>
                <div className={css.avatarWrapper}>
                  <Field
                    accept={ACCEPT_IMAGES}
                    id="profileImage"
                    name="profileImage"
                    label={chooseAvatarLabel}
                    type="file"
                    form={null}
                    uploadImageError={uploadImageError}
                    disabled={uploadInProgress}
                  >
                    {fieldProps => {
                      const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                      const { name, type } = input;
                      const onChange = e => {
                        const file = e.target.files[0];
                        form.change(`profileImage`, file);
                        form.blur(`profileImage`);
                        if (file != null) {
                          const tempId = `${file.name}_${Date.now()}`;
                          onImageUpload({ id: tempId, file });
                        }
                      };

                      let error = null;

                      if (isUploadImageOverLimitError(uploadImageError)) {
                        error = (
                          <div className={css.error}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                          </div>
                        );
                      } else if (uploadImageError) {
                        error = (
                          <div className={css.error}>
                            <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                          </div>
                        );
                      }

                      return (
                        <div className={css.uploadAvatarWrapper}>
                          <label className={css.label} htmlFor={id}>
                            {label}
                          </label>
                          <input
                            accept={accept}
                            id={id}
                            name={name}
                            className={css.uploadAvatarInput}
                            disabled={disabled}
                            onChange={onChange}
                            type={type}
                          />
                          {error}
                        </div>
                      );
                    }}
                  </Field>
                </div>
              </div>

              <div className={css.sectionContainer}>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    label={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    validate={firstNameRequired}
                  />
                  <FieldTextInput
                    className={css.lastName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    label={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    validate={lastNameRequired}
                  />
                </div>
                <div className={css.rowContainer}>
                  <FieldBirthdayInput
                    id={`birthday`}
                    name="birthday"
                    label={birthdayLabel}
                    format={identity}
                    valueFromForm={values.birthday}
                  />
                </div>
                <div className={css.nameContainer}>
                  <FieldSelectSimple
                    className={css.addressState}
                    type="text"
                    id="codePhoneNumber"
                    name="codePhoneNumber"
                    defaultValue={'+521'}
                    label={'Lada'}
                  >
                    <option value="+521" selected="selected">
                      (MX)+521
                    </option>

                    {codesPhone?.map(codePhone => (
                      <option value={`+${codePhone?.code}`} selected="selected">
                        {`(${codePhone?.iso})+${codePhone?.code}`}
                      </option>
                    ))}
                  </FieldSelectSimple>

                  <FieldPhoneNumberInput
                    className={css.addressCity}
                    name="phoneNumber"
                    id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                    label={phoneLabel}
                    onBlur={e => functionChangeNumber(e.target.value)}
                    placeholder={phonePlaceholder}
                    maxLength={10}
                    validate={phoneRequired}
                  />
                  {alreadyTakenPhone ? (
                    <div className={css.error}>
                      El número introducido ya tiene una cuenta asociada
                    </div>
                  ) : null}
                </div>
              </div>

              <div className={classNames(css.sectionContainer, css.lastSection)}>
                <h3 className={css.sectionTitle}>
                  <FormattedMessage id="ProfileSettingsForm.address" />
                </h3>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.address}
                    type="text"
                    id="address"
                    name="address"
                    label={addressLabel}
                    placeholder={addressPlaceholder}
                    validate={composeValidetors}
                  />
                  <FieldTextInput
                    className={css.addressExtNumber}
                    type="text"
                    id="addressExtNumber"
                    name="addressExtNumber"
                    label="Número Exterior"
                    placeholder="Número Exterior"
                  />
                  <FieldTextInput
                    className={css.addressIntNumber}
                    type="text"
                    id="addressIntNumber"
                    name="addressIntNumber"
                    label="Número Interior"
                    placeholder="Número Interior"
                  />
                </div>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.addressNeighborhood}
                    type="text"
                    id="addressNeighborhood"
                    name="addressNeighborhood"
                    label="Colonia"
                    placeholder="Colonia"
                    validate={composeValidetors}
                  />
                  <FieldTextInput
                    className={css.addressZip}
                    type="text"
                    id="addressZip"
                    name="addressZip"
                    label="C.P."
                    placeholder="C.P."
                    customErrorText={zipErrorMessage}
                  />
                </div>
                <div className={css.nameContainer}>
                  <FieldSelectSimple
                    className={css.addressState}
                    id="addressState"
                    name="addressState"
                    label="Estado"
                  >
                    <option disabled value="">
                      Estado
                    </option>
                    {mexicoStates.map(state => {
                      return (
                        <option key={state.clave} value={state.clave}>
                          {state.nombre}
                        </option>
                      );
                    })}
                  </FieldSelectSimple>
                  <FieldTextInput
                    className={css.addressCity}
                    type="text"
                    id="addressCity"
                    name="addressCity"
                    label="Municipio"
                    placeholder="Municipio"
                  />
                </div>
              </div>

              <div className={css.actionsContainer}>
                {submitError}
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={pristineSinceLastSubmit && !this.props.isUnavailable}
                >
                  <FormattedMessage id="ProfileSettingsForm.saveChanges" />
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  uploadCoverImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  uploadCoverImageError: propTypes.error,
  uploadCoverInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
