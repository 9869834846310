import React, { useState } from 'react'
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Modal, Button, PrimaryButton } from '../../components'
import { injectIntl } from '../../util/reactIntl'
import { FormattedMessage } from 'react-intl'
import FieldCurrencyInput from '../../components/FieldCurrencyInput/FieldCurrencyInput'
import * as validators from '../../util/validators';

import { Form as FinalForm, FormSpy } from 'react-final-form';
import config from '../../config.js';
import { types as sdkTypes } from '../../util/sdkLoader';



import css from './ListingPage.css';

let RELOVE_COMMISSION_PERCENTAGE;
let RELOVE_BASE_COMMISSION;

const { Money } = sdkTypes;

const SectionPriceDiscountMaybe = props => {

    const [valueState, setValueState] = useState(0);
    const [currentValueState, setCurrentValueState] = useState(0);
    const [isDefaultPriceUsed, setIsDefaultPriceUsed] = useState(true);

    const formRef = React.useRef(null)
    let fieldref = React.useRef(null)
    

    const {
        listing,
        onSubmitNewPrice,
        intl,
        onManageDisableScrolling,
        isOwnListing,
        inProgress,
        isUserLoggedIn,
        isDiscountOfferModalOpen,
        onCloseDiscountOfferModal,
        onOpenDiscountOfferModal,
        lowerListingPriceInProgress,
        initialValues,
      } = props;

    const handleLowerPriceSubmit = (e) => {
        e.persist();
        onSubmitNewPrice(currentValueState);
    }
    return isUserLoggedIn ? (
        isOwnListing ? (
            <div className={css.sectionActions}>
              <Modal
                id="discountOffer"
                isOpen={isDiscountOfferModalOpen}
                containerClasses={css.modalContainer}
                onClose={e => {
                  setCurrentValueState(new Money((listing.attributes.price.amount *.75).toFixed(2), config.currency))
                  fieldref.current.state.value = ((listing.attributes.price.amount *.75) / 100).toFixed(2);
                  fieldref.current.state.formattedValue = `${((listing.attributes.price.amount *.75) / 100).toFixed(2)}`;
                  fieldref.current.state.unformattedValue = `${((listing.attributes.price.amount *.75) / 100).toFixed(2)}`;
                  onCloseDiscountOfferModal();
                  setIsDefaultPriceUsed(true)
                }}
                usePortal
                contentClassName={css.modalContent}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div>
                  {/* <h2 className={modalCss.title}>Ingresa el nuevo precio</h2> */}
                  <div>
                    <FinalForm
                      onSubmit={handleLowerPriceSubmit}
                      action=""
                      method="GET"
                      initialValues={initialValues}
                      render={({form}) => {
                        formRef.current = form
                        const priceRequired = validators.required(
                            intl.formatMessage({
                              id: 'EditListingPricingForm.priceRequired',
                            })
                          );


                          const priceTier = listing.attributes.price.amount < 200000 
                          ? "Tier 1"
                          : listing.attributes.price.amount < 300000 ? "Tier 2"
                          : listing.attributes.price.amount < 500000 ? "Tier 3"
                          : "Tier 4"

                          let maxPrice, maxPriceMessage;
                          switch (priceTier) {
                            case "Tier 1":
                              maxPrice = new Money(listing.attributes.price.amount * .85, config.currency);
                              maxPriceMessage = `Intenta con un descuento de al menos 15%`;  
                              break;
                            case "Tier 2":
                              maxPrice = new Money(listing.attributes.price.amount - 30000, config.currency);
                              maxPriceMessage = `Intenta con un descuento de al menos $300`;   
                              break;
                            case "Tier 3":
                              maxPrice = new Money(listing.attributes.price.amount * .90, config.currency);
                              maxPriceMessage = `Intenta con un descuento de al menos 10%`;   
                              break;
                            case "Tier 4":
                              maxPrice = new Money(listing.attributes.price.amount - 50000, config.currency);
                              maxPriceMessage = `Intenta con un descuento de al menos $500`;   
                              break;
                            default:
                              break;
                          }

                          const minPriceRequired = validators.moneySubUnitAmountAtLeast(
  
                            <div className={css.priceTooHighErrorMessage}>El precio mínimo es de $150.</div>,
                            config.listingMinimumPriceSubUnits
                          );

                          const maxPriceRequired = validators.moneySubUnitAmountAtMost(
                            <div className={css.priceTooHighErrorMessage}>{maxPriceMessage}</div>,
                            maxPrice.amount
                          );

                          const priceValidators = config.listingMinimumPriceSubUnits
                            ? validators.composeValidators(priceRequired, minPriceRequired, maxPriceRequired)
                            : priceRequired;

                        const submitDisabled = currentValueState < config.listingMinimumPriceSubUnits/100 || currentValueState > maxPrice.amount/100;

                        return (
                          <form
                            onSubmit={e => {
                              e.preventDefault();
                              // handleSubmit(e);
                            }}
                          >
                            <FormSpy onChange={e => {
                                if(e.dirty){
                                  setIsDefaultPriceUsed(false)
                                }
                                if(e.values?.offer?.amount || e.values?.offer?.amount === 0){
                                  setCurrentValueState(e.values?.offer?.amount / 100);
                                  let proposedAmount = e.values?.offer?.amount
                                  if(proposedAmount < 32500){
                                    RELOVE_COMMISSION_PERCENTAGE = 0;
                                    RELOVE_BASE_COMMISSION = 75;
                                  }
                                  else{
                                    RELOVE_COMMISSION_PERCENTAGE = 0.185;
                                    RELOVE_BASE_COMMISSION = 15;
                                  }

                                  
                                  const providerEarnings = (Number((proposedAmount * (1 - RELOVE_COMMISSION_PERCENTAGE) / 100) - RELOVE_BASE_COMMISSION).toFixed(2)).replace( /\d{1,3}(?=(\d{3})+(?!\d))/g , "$&,");
                                  setValueState(providerEarnings)
                                }
                            }} />
                            <div className={css.lowerPriceFieldsContainer}>
                            <FieldCurrencyInput
                              id="offer"
                              name="offer"
                              label={ isDefaultPriceUsed ? "Recomendado" : "En gloset"}
                              currencyConfig={config.currencyConfig}
                              validate={priceValidators}
                              className={css.lowerPriceInput}
                              isAlternativeField={true}
                              newClass={css.centeredInput}
                              fieldref={fieldref}
                              currentValueState={currentValueState}
                            />
                            <div className={css.lowerPriceProviderCalc}> 
                                <div className={css.calcPriceLabel}>Para ti:</div>
                                <div>${valueState}</div>
                            </div>
                            </div>
                            <br/>
                              <PrimaryButton 
                                type="button" 
                                onClick={handleLowerPriceSubmit} 
                                disabled={submitDisabled}
                                inProgress={lowerListingPriceInProgress}
                                className={css.priceDiscountModalButton}
                              >
                                  <FormattedMessage id="ListingPage.lowerPrice" />
                              </PrimaryButton>
                          </form>
                        );
                      }
                    }
                    >
                    </FinalForm>
                    <div className={css.lowerPriceMessage}><FormattedMessage id="ListingPage.lowerPriceMessage"/></div>
                  </div>
                </div>
              </Modal>
              <Button
                className={css.discountButton}
                onClick={onOpenDiscountOfferModal}
                inProgress={inProgress}  
              >
                <div className={css.priceDiscountText}>Bajar Precio</div>
              </Button>
            </div>
    ) : null
    ): null
}

// export default SectionActions;
export default compose(
    withRouter,
    injectIntl
  )(SectionPriceDiscountMaybe);
  