import React from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../../components';

import css from './ReloveEditListingForm.css';

const ReloveDatalistFieldComponent = props => {
  const {
    className,
    id,
    listId,
    label,
    options,
    input,
    meta,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { valid, invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames(css.datalist, {
    [css.datalistSuccess]: valid,
    [css.datalistError]: hasError,
  });
  const inputProps = { className: selectClasses, id, ...input, ...rest };

  return options ? (
    <section className={className} >
      {label ? <label htmlFor={id}>{label}</label> : null}
      <input list={listId} {...inputProps} />
      <datalist id={listId}>
        {options.map(c => (
          <option key={c.key} value={c.label} />
        ))}
      </datalist>
      <ValidationError fieldMeta={meta} />
    </section>
  ) : null;
};

const ReloveDatalistField = props => {
  return <Field component={ReloveDatalistFieldComponent} {...props} />;
};

export default ReloveDatalistField;
