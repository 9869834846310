import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  ShippingBanner,
  SectionFollowingLanding,
  SectionTrends,
  SectionHowItWorks,
  SectionFeatured,
  SectionLikesHome,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SuscriptionSection,
  AuthenticationPageModalWrapper
} from '../../components';

import { TopbarContainer } from '../../containers';
import { loadData, sendEmail } from './LandingPage.duck';
import { queryFavoritedListings, getListingsPage } from '../MyFavoritesPage/MyFavoritesPage.duck'; //'./MyFavoritesPage.duck';

import facebookImage from '../../assets/default-profile-banner.jpg';
import twitterImage from '../../assets/default-profile-banner.jpg';
import css from './LandingPage.css';
import { upsertSubscriberInSegment } from '../../util/api';
import { logEvent } from '../../util/logsEvent';

import WhatsAppChat from '../../components/WhatsAppChat/WhatsAppChat.js';
// import { ReferralButton } from '../../components/Button/Button';
// import { ShoppingCartButton } from '../../components/Button/Button';

const MAX_MOBILE_SCREEN_WIDTH = 768;

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    onManageDisableScrolling,
    viewport,
    scrollingDisabled,
    currentUser,
    getFavorites,
    getPage,
    isAuthenticated,
    featuredListings,
    currentUserFollowing,
    currentUserFollowingInProgress,
    onSendEmail,
  } = props;

  //Function to get if someone has accepted the cookies of the site
  function getCookie() {
    if (localStorage.getItem('emailSubscription') === null) {
      localStorage.setItem('emailSubscription', 1);
      return false;
    }
    return true;
  }
  const sendEmail = value => {
    onSendEmail(value.email)
      .then(() => {
        upsertSubscriberInSegment({ email: value.email, segmentIds: ['63a077866ce66dd0dcdc5d83'] });
        logEvent('Email Promo Submitted', { email: value.email })
        setOpenEvent(false);
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  };

  const [setOpen, setOpenEvent] = useState(false);

  const [showWhatsAppChat, setShowWhatsAppChat] = useState(true);

  const [authContainerOpen, setAuthContainerOpen] = useState(false);

  const openModal = () => {
    const isThereCookies = getCookie();
    if (isAuthenticated === false && isThereCookies === false) {
      setOpenEvent(true);
    }
  };

  const handleWhatsAppClose = () => {
    localStorage.setItem('hideWhatsAppChat', 'true');
    setShowWhatsAppChat(false)
  }

  useEffect(() => {
    async function fetchData() {
      if (!!currentUser && !!currentUser.id) {
        const listings = await getFavorites(currentUser.id.uuid);
        getPage(currentUser.id.uuid, listings, 0);
      }
    }
    fetchData();
  }, [currentUser, getFavorites, getPage]);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  useEffect(() => {
    setTimeout(openModal, 4000);
  }, []);

  const { favoritedListings } = props;


  const handleLoginRequest = (listing) => {
    setAuthContainerOpen(true)
  }

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaTitleSub = intl.formatMessage({ id: 'LandingPage.schemaTitleSub' }, { siteTitle });

  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const HowItWorksSectionEl = useRef(null);

  const onScrollToEl = () => {
    if (!!HowItWorksSectionEl.current) {
      let element = HowItWorksSectionEl.current;
      let headerOffset = 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={!!currentUser ? schemaTitleSub : schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      listing
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: !!currentUser ? schemaTitleSub : schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          {isAuthenticated ? (
            <ShippingBanner
              message="¿Primera compra? Usa el código HOLAGLOSET y obtén $150"
              link={'https://gloset.mx/s?hotlist=true&featured=true&sort=-pub_updatedAt'}
            />
          ) : (
            <ShippingBanner
              message="¿Primera compra? Usa el código HOLAGLOSET y obtén $150"
              link={'https://invita.gloset.mx/iKHiFeqCbJ67YWf26'}
            />
          )}
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {setOpen ? (
            <div className={css.heroContainer}>
              <SuscriptionSection
                onManageDisableScrolling={onManageDisableScrolling}
                onSubmitEnquiry={sendEmail}
                onCloseEnquiry={() => {
                  setOpenEvent(false);
                  logEvent('Email Promo Cancelled')
                }}
              ></SuscriptionSection>
            </div>
          ) : null}
          {!isAuthenticated ? (
            <div className={css.heroContainer}>
              <SectionHero
                className={css.hero}
                history={history}
                location={location}
                isAuthenticated={isAuthenticated}
                scrollHandler={onScrollToEl}
              />
            </div>
          ) : (
            <div className={css.heroContainer}></div>
          )}
          <div className={css.sections}>
            <Grid container spacing={2}>
              <Grid item className={css.aside}>
                {/*Aquí estaban los botones*/}

                {!isMobileLayout ? (
                  //<SectionPopularBrands />
                  <SectionLikesHome
                    favoritedListings={favoritedListings}
                    isMobileLayout={isMobileLayout}
                  />
                ) : null}
              </Grid>
              <Grid item className={css.main}>
                {isAuthenticated && currentUserFollowing && currentUser ? (
                  <div className={css.section}>
                    <SectionFollowingLanding
                      currentUser={currentUser}
                      following={currentUserFollowing}
                      inProgress={currentUserFollowingInProgress}
                      isMobileLayout={isMobileLayout}
                    />
                  </div>
                ) : null}
                {isMobileLayout ? (
                  <div className={css.section}>
                    {/* <SectionPopularBrands isMobileLayout={isMobileLayout} /> */}
                    <SectionLikesHome
                      favoritedListings={favoritedListings}
                      isMobileLayout={isMobileLayout}
                    />
                  </div>
                ) : null}
                <div className={css.section}>
                  <SectionTrends />
                </div>
                <div className={css.section}>
                  <SectionFeatured 
                    featuredListings={featuredListings} 
                    handleLoginRequest={handleLoginRequest}
                  />
                </div>
              </Grid>
            </Grid>
            {!isAuthenticated ? (
              <div className={css.section} ref={HowItWorksSectionEl}>
                <SectionHowItWorks />
              </div>
            ) : null}
          </div>

          {showWhatsAppChat && (
          <WhatsAppChat
            phoneNumber="5218123465519" 
            message="Tengo una pregunta" 
            componentTextShown={true}
            onClose={() => handleWhatsAppClose()} // Pass the close handler
            showText={false}
          />
        )}

          <AuthenticationPageModalWrapper
            authContainerOpen={authContainerOpen}
            closeAuthModal={() => setAuthContainerOpen(false)}
          //  onLoginCompleted={() => {this.handleLoginRedirecting()}}
          //  requiresRedirection={this.state.requiresRedirection}
            // extraAction={() => this.triggerExtraAction(this.state.neededAction)}
            // requiresExtraAction={this.state.requiresExtraAction}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object, arrayOf, func } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  favoritedListings: arrayOf(PropTypes.listing).isRequired,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSendEmail: func.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserFollowing, currentUserFollowingInProgress } = state.user;
  const { isAuthenticated } = state.Auth;
  const { featuredListingsIds } = state.LandingPage;
  const { favoritedListingsIds } = state.MyFavoritesPage;

  const pageListings = getListingsById(state, featuredListingsIds, 'pageListings');
  const listings = getListingsById(state, favoritedListingsIds, 'listings');
  const usersIds = currentUserFollowing.map(i => ({ id: i, type: 'user' }));
  const followingUsers = getMarketplaceEntities(state, usersIds) || [];

  return {
    featuredListings: pageListings,
    favoritedListings: listings,
    isAuthenticated,
    currentUser,
    currentUserFollowing: followingUsers,
    currentUserFollowingInProgress,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  getFavorites: user => dispatch(queryFavoritedListings(user)),
  getPage: (user, listings, page) => dispatch(getListingsPage(user, listings, page)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSendEmail: email => dispatch(sendEmail(email)),
});
// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = () => {
  return loadData();
};

export default LandingPage;
