import React from 'react';
import {
  Button, 
  Modal,
  Form, 
  FieldTextInput,
  FieldSelectSimple,
  FieldSelect
 } from '../../components';

import config from '../../config';
import * as validators from '../../util/validators';

import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { Form as FinalForm, Field, FormSpy } from 'react-final-form';

import css from './CheckoutPage.css';

const SectionAddress = props => (
  <FinalForm
  {...props}
  render={formRenderProps => {

    const {
      isAddressModalOpen,
      onCloseAddressModal,
      handleSubmit,
      manageDisableScrolling,
      zipIsUnavailable,
      intl,
      updateInProgress,
      updateError,
      invalid,

      setNewAddressState,
      setAddressValid
    } = formRenderProps;

    // Address
    const addressLabel = intl.formatMessage({
      id: 'ProfileSettingsForm.addressLabel',
    })
    const addressPlaceholder = intl.formatMessage({
      id: 'ProfileSettingsForm.addressPlaceholder',
    })
    const addressRequiredMessage = intl.formatMessage({
      id: 'ProfileSettingsForm.addressRequired',
    });

    const addressRequired = validators.required(addressRequiredMessage);

    const maxCharacters = validators.maxLength(
      "Longitud máxima de campo son 30 carácteres",
      30
    )
    const zipErrorMessage = zipIsUnavailable ? intl.formatMessage({
      id: 'ProfileSettingsForm.zipUnavailableShortError',
    }) : null


    const composedValidator = validators.composeValidators(addressRequired, maxCharacters)


    const handleAnyFieldChange = (field, value, isInvalid) => {

      let obj = {}

      obj[field] = value

      setNewAddressState(field, value, isInvalid)
    }


    const mexicoStates = config.states;



    // Validation function for the state field
const validateState = value => {
  // Assuming the placeholder value is an empty string and is considered invalid
  // Adjust the condition based on your specific needs
  if (!value || value === "") {
    return "State is required"; // This message will be displayed if validation fails
  }
  // If the function returns undefined, it means validation passed
};

  return (
      <div  className={css.AddressContainer}>
      <Form
      className={css.form}
      onSubmit={handleSubmit}
      >         
        <div className={classNames(css.sectionContainer, css.lastSection)}>
        <div className={css.nameContainer}>

        <Field name={"address_line"}>
          {({ input, meta }) => {

            const customOnChange = (e) => {
              // Execute any additional logic here before calling React Final Form's onChange
              handleAnyFieldChange('address_line', e.target.value, e.target.value.length <= 30);
              // Call React Final Form's onChange to ensure the form state is updated
              input.onChange(e);
            };

            return (
            <FieldTextInput
              inputRootClass={css.address_line}
              className={css.inputRoot}
              type="text"
              id="address_line"
              name="address_line"
              label={addressLabel}
              placeholder={addressPlaceholder}
              validate={composedValidator}
              autoComplete="off"
              onChange={customOnChange}
            />
            
            )
          }}

          </Field>

        </div>
        <div className={css.addressRowContainer}>
          <Field name={"ext_number"}>
            {({ input, meta }) => {

              const customOnChange = (e) => {
                // Execute any additional logic here before calling React Final Form's onChange
                handleAnyFieldChange('ext_number', e.target.value, meta.valid);
                // Call React Final Form's onChange to ensure the form state is updated
                input.onChange(e);
              };

              return (
                <FieldTextInput
                  inputRootClass={css.ext_number}
                  className={css.inputRoot}
                  type="text"
                  id="ext_number"
                  name="ext_number"
                  label="Número Exterior"
                  placeholder="Número Exterior"
                  validate={addressRequired}
                  autoComplete="off"
                  onChange={customOnChange}
                />
              )
            }}
          </Field>
          <Field name={"int_number"}>
            {({ input, meta }) => {

              const customOnChange = (e) => {
                // Execute any additional logic here before calling React Final Form's onChange
                handleAnyFieldChange('int_number', e.target.value, true);
                // Call React Final Form's onChange to ensure the form state is updated
                input.onChange(e);
              };

              return (
                <FieldTextInput
                  inputRootClass={css.int_number}
                  className={css.inputRoot}
                  type="text"
                  id="int_number"
                  name="int_number"
                  label="Número Interior"
                  placeholder="Número Interior"
                  autoComplete="off"
                  onChange={customOnChange}
                />
              )
            }}
          </Field>
        </div>
        <div className={css.nameContainer}>
        <Field name={"neighborhood"}>
          {({ input, meta }) => {

            const customOnChange = (e) => {
              // Execute any additional logic here before calling React Final Form's onChange
              handleAnyFieldChange('neighborhood', e.target.value, e.target.value.length <= 30);
              // Call React Final Form's onChange to ensure the form state is updated
              input.onChange(e);
            };

            return (
              <FieldTextInput
                inputRootClass={css.neighborhood}
                className={css.inputRoot}
                type="text"
                id="neighborhood"
                name="neighborhood"
                label="Colonia"
                placeholder="Colonia"
                validate={composedValidator}
                autoComplete="off"
                onChange={customOnChange}
              />
            )
          }}
        </Field>
        </div>
        <div className={css.addressRowContainer}>
        <Field name={"zip"}>
          {({ input, meta }) => {

            const customOnChange = (e) => {
              // Execute any additional logic here before calling React Final Form's onChange
              handleAnyFieldChange('zip', e.target.value, meta.valid);
              // Call React Final Form's onChange to ensure the form state is updated
              input.onChange(e);
            };

            return (
              <FieldTextInput
                inputRootClass={css.zip}
                className={css.inputRoot}
                type="text"
                id="zip"
                name="zip"
                label="C.P."
                placeholder="C.P."
                validate={addressRequired}
                customErrorText={zipErrorMessage}
                autoComplete="off"
                onChange={customOnChange}
              />
            )
          }}
        </Field>
        <Field name={"city"}>
          {({ input, meta }) => {

            const customOnChange = (e) => {
              // Execute any additional logic here before calling React Final Form's onChange
              handleAnyFieldChange('city', e.target.value, meta.valid);
              // Call React Final Form's onChange to ensure the form state is updated
              input.onChange(e);
            };

            return (
              <FieldTextInput
                inputRootClass={css.city}
                className={css.inputRoot}
                type="text"
                id="city"
                name="city"
                label="Ciudad"
                placeholder="Ciudad"
                validate={addressRequired}
                autoComplete="off"
                onChange={customOnChange}
              />
            )
          }}
        </Field>
        </div>
        <div className={css.nameContainer}>
        <Field name="state" validate={addressRequired}>
          {({ input, meta }) => {
            // Custom onChange that directly receives the selected value instead of an event
            const customOnChange = (value) => {
              handleAnyFieldChange('state', value, false); // Assuming this marks the field as valid
              
              // Directly using the value since 'e' is the value itself
              input.onChange(value); // Ensures React Final Form's form state is updated correctly
            };

            return (
              <FieldSelect
                id="state"
                name="state"
                label="Estado"
                autoComplete="off"
                // Pass the customOnChange function, expecting the direct value
                onChange={customOnChange}
                options={mexicoStates}
                hasInternalCss={true}
                internalCss={css.addressState}
              >
                <option disabled value="">
                  Estado
                </option>
                {mexicoStates.map(state => (
                  <option key={state.clave} value={state.clave}>
                    {state.nombre}
                  </option>
                ))}
              </FieldSelect>
            );
          }}
      </Field>

      <FormSpy
              subscription={{ values: true, valid: true }} // Only subscribes to changes in form validity
              onChange={({values, valid}) => {
                setAddressValid(valid && values.state)
              }}
            />      
        </div>
              {updateError ? (
              <p className={css.error}>
                <FormattedMessage id="CheckoutPage.updateError" />
              </p>
            ) : null}
        </div>
      </Form>
  </div>
  )

  }}
  />
)

export default SectionAddress
