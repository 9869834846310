import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from "react-final-form-listeners";
import  FieldSelect from '../../../components/FieldSelectSimple/FieldSelectSimple';
import css from './ReloveEditListingForm.css';


const ResetOnChange = ({ field, set}) => (
  <Field name={set} subscription={{}}>
    {(
      // No subscription. We only use Field to get to the change function
      { input: { onChange } }
    ) => (
      <FormSpy subscription={{}}>
        {({ form }) => (
          <OnChange name={field}>
            {value => {
                onChange(undefined);
            }}
          </OnChange>
        )}
      </FormSpy>
    )}
  </Field>
);

const ReloveCategorySelectField = props => {
  const {
    categoryName,
    categoryId,
    categoryLabel,
    categoryPlaceholder,
    categoryValidator,
    subCategoryName,
    subCategoryId,
    subCategoryLabel,
    subCategoryPlaceholder,
    options,
    sizeName,
    className
  } = props;

  return options ? (
    <React.Fragment>
      <ResetOnChange field={categoryName} set={subCategoryName} />
      <ResetOnChange field={categoryName} set={sizeName} />
      <FieldSelect
        className={className || css.category}
        name={categoryName}
        id={categoryId}
        label={categoryLabel}
        validate={categoryValidator}
        options={options}
      >

        <option value="" className={css.placeholderOption}>
        {categoryPlaceholder}
      </option>
       {options.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
        ))}

      </FieldSelect>
      <FormSpy
        subscription={{values: true}}
        render={(form) => {
            const category = options.find(group => group.key === form.values[categoryName]);
            const subOptions = category ? category.options : [];
            return (
              <FieldSelect
                className={className || css.category}
                name={subCategoryName}
                id={subCategoryId}
                label={subCategoryLabel}
                disabled={!subOptions}
                options={subOptions}
                placeholder = {subCategoryPlaceholder}
              >
                <option value="">
                  {subCategoryPlaceholder}
                </option>
                {subOptions.map(c => (
                  <option key={c.key} value={c.key}>
                    {c.label}
                  </option>
                ))}
              </FieldSelect>
            );
          }
        }
      />
    </React.Fragment>
  ) : null;
};

export default  ReloveCategorySelectField;
